export default {
  "title": "Exports Loader options",
  "definitions": {
    "ExportItemString": {
      "type": "string",
      "minLength": 1
    },
    "ExportItemObject": {
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "syntax": {
          "enum": ["default", "named", "single", "multiple"]
        },
        "name": {
          "type": "string",
          "minLength": 1
        },
        "alias": {
          "type": "string",
          "minLength": 1
        }
      },
      "required": ["name"]
    },
    "ExportItem": {
      "anyOf": [
        {
          "$ref": "#/definitions/ExportItemString"
        },
        {
          "$ref": "#/definitions/ExportItemObject"
        }
      ]
    }
  },
  "type": "object",
  "additionalProperties": false,
  "properties": {
    "type": {
      "enum": ["commonjs", "module"],
      "description": "Format of generated exports.",
      "link": "https://github.com/webpack-contrib/exports-loader#type"
    },
    "exports": {
      "anyOf": [
        {
          "type": "string",
          "minLength": 1
        },
        {
          "$ref": "#/definitions/ExportItem"
        },
        {
          "type": "array",
          "items": {
            "$ref": "#/definitions/ExportItem"
          },
          "minItems": 1
        }
      ],
      "description": "List of exports.",
      "link": "https://github.com/webpack-contrib/exports-loader#exports"
    }
  },
  "required": ["exports"]
}
;